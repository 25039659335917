/**
 *
 * @param {Object[]} faqCategoryNames
 * @returns {Object{sortedFaqs:[], categoryFootnotes:[], schema:{}}}
 */
// Hooks
import useFaqData from "../hooks/use-faq-data";
import useFaqCategoriesData from "../hooks/use-faq-categories-data";

/* eslint-disable no-console */
const SortedFaqDataByCategory = (faqCategoryNames = [], metaEntitiesLimit = 15) => {
  // console.log("getSortedFaqDataByCategory() faqCategoryNames: ", faqCategoryNames);
  if (faqCategoryNames.length === 0) {
    throw new Error("Parameter faqCategoryNames array is required!");
  }

  // use custom hooks to query the FAQ data from Strapi
  const { allFaqs } = useFaqData();
  const { allFaqCategories } = useFaqCategoriesData();

  let sortedFaqs = [];
  let categoryFootnotes = [];

  for (let categoryName of faqCategoryNames) {
    let foundCategory = false;
    for (let category of allFaqCategories) {
      // ensure that the faq category name props match an actual faq_categories.CategoryName in Strapi
      if (category.CategoryName === categoryName) {
        foundCategory = true;
        // get all the FAQs in this category by categoryName
        const catFaqs = allFaqs.filter((faq) => faq.FaqCategoryNames.includes(categoryName));
        // console.log(`Found ${catFaqs.length} FAQs in ${categoryName}`);

        // if there is an FAQ Display Order List array (of FAQ IDs) on the category in Strapi
        if (category.FaqDisplayOrderList) {
          if (catFaqs.length >= category.FaqDisplayOrderList.length) {
            // console.log(`Sorting by FaqDisplayOrderList`, category.FaqDisplayOrderList);
            catFaqs.sort(function (a, b) {
              // IDs in Strapi are Strings, but we to match them against Integers in category.FaqDisplayOrderList
              let aId = parseInt(a.id);
              let bId = parseInt(b.id);
              return category.FaqDisplayOrderList.indexOf(aId) - category.FaqDisplayOrderList.indexOf(bId);
            });
            //console.log("sorted catFaqs: ", catFaqs);
          } else {
            console.warn(`FAQs [${catFaqs.length}] < FaqDisplayOrderList [${category.FaqDisplayOrderList.length}]`);
          }
        }
        // else {
        //   console.log(`No FaqDisplayOrderList defined`);
        // }

        sortedFaqs = [...sortedFaqs, ...catFaqs];
        // capture any category Footnotes
        if (category.Footnotes !== null) {
          categoryFootnotes.push(category.Footnotes);
        }
      }
    }
    if (!foundCategory) {
      console.warn(`FAQ CategoryName "${categoryName}" not found in Strapi!`);
    }
  }

  // build schema for SEO
  let schemaFaqs = [];
  if (metaEntitiesLimit) {
    const end = metaEntitiesLimit > sortedFaqs.length ? sortedFaqs.length : metaEntitiesLimit;
    // console.log(`Limiting schema mainEntities to ${end} FAQs`);
    schemaFaqs = sortedFaqs.slice(0, end);
  }

  let metaEntities = [];
  for (let faq of schemaFaqs) {
    // NOTE Google Rich Snippets only support the following HTML tags:
    // <h1> through <h6>, <br>, <ol>, <ul>, <li>, <a>, <p>, <div>, <b>, <strong>, <i>, and <em>.
    if (faq.Question && faq.Answer) {
      let entity = {
        "@type": "Question",
        name: `${faq.Question}`,
        acceptedAnswer: {
          "@type": "Answer",
          text: `${faq.Answer}`
        }
      };
      metaEntities.push(entity);
    }
  }

  const schema = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: metaEntities
  };

  return {
    sortedFaqs,
    categoryFootnotes,
    schema
  };
};

export default SortedFaqDataByCategory;
