import { useStaticQuery, graphql } from "gatsby";

export default function useFaqData() {
  const faqData = useStaticQuery(graphql`
    query {
      allStrapiFaqs(sort: { fields: id, order: ASC }, filter: { Active: { eq: true } }) {
        nodes {
          id
          Active
          Answer
          Question
          FaqIcon {
            Name
            Lib
            Class
          }
          FaqVideo {
            Title
            VideoURL
            VimeoId
          }
          faq_categories {
            CategoryName
          }
        }
      }
    }
  `);

  const allFaqs = faqData.allStrapiFaqs.nodes.map((node) => {
    const { id, Active, Answer, Question, FaqIcon, FaqVideo, faq_categories } = node;
    return {
      id: id.replace("Faqs_", ""),
      Active,
      Answer,
      Question,
      FaqIcon,
      FaqVideo,
      FaqCategoryNames: faq_categories.map((cat) => cat.CategoryName)
    };
  });

  return { allFaqs };
}
