import { useStaticQuery, graphql } from "gatsby";

export default function useFaqCategoriesData() {
  const faqData = useStaticQuery(graphql`
    query {
      allStrapiFaqCategories(sort: { order: ASC, fields: CategoryName }) {
        nodes {
          id
          Active
          CategoryName
          Footnotes
          FaqDisplayOrderList
          faqs {
            id
            Question
          }
        }
      }
    }
  `);

  const allFaqCategories = faqData.allStrapiFaqCategories.nodes.map((node) => {
    const { id, Active, CategoryName, Footnotes, FaqDisplayOrderList, faqs } = node;
    return {
      id: id.replace("Faq-categories_", ""),
      Active,
      CategoryName,
      Footnotes,
      FaqDisplayOrderList,
      FaqIds: faqs.map((faq) => faq.id)
    };
  });

  return { allFaqCategories };
}
