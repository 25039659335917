import React from "react";

// components
import Breadcrumb from "../../../components/breadcrumb/breadcrumb";
import SEO from "../../../components/seo/seo";
import SecondaryLanding from "../../../components/layouts/secondary-landing";
import FaqAccordion from "../../../components/faq/faq-accordion";
import VimeoVideoFrame from "../../../components/custom-widgets/vimeo-video-frame";
import MktoForm from "../../../components/mkto-form/mkto-form";

// Helpers
// import showMktoForm from "../../../helpers/showMktoForm";
import getSortedFaqDataByCategory from "../../../helpers/getSortedFaqDataByCategory";

const OnlineBankingFAQ = () => {
  const { sortedFaqs, categoryFootnotes, schema } = getSortedFaqDataByCategory(["Online & Mobile Banking"]);

  const title = "Online & Mobile Banking FAQs",
    description =
      "Have a question about online or mobile banking at WaFd Bank? Find out about our online banking and mobile app enhancements to help you manage your money easier.";
  const seoData = {
    title: title,
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        content: title
      },
      {
        name: "description",
        content: description
      },
      {
        property: "og:title",
        content: title
      },
      {
        property: "og:description",
        content: description
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/personal-banking/online-banking/faq"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/thumb-online-banking-02-250.jpg"
      }
    ],
    schema
  };

  const breadcrumbData = [
    {
      id: 1,
      url: "/personal-banking",
      title: "Personal Banking"
    },
    {
      id: 2,
      url: "/personal-banking/online-banking",
      title: "Mobile & Online Banking"
    },
    {
      id: 3,
      active: true,
      title: "FAQs"
    }
  ];

  const faqAccordionData = {
    id: "online-and-mobile-banking-faq-sections",
    pageTitle: "Frequently Asked Questions - WaFd Bank's New Digital Banking",
    title: null,
    // pass faqData instead of faqCategoryNames
    faqData: {
      sortedFaqs,
      categoryFootnotes
    }
  };

  const mobileBankingVideoData = {
    title: "Getting Started With Mobile Banking",
    vimeoId: "651716485",
    minHeight: "360px"
  };

  /*
  const TBD = {
    question: "Does WaFd Bank support Quicken and Quickbooks?",
    answer: (
      <>
        Yes, WaFd Bank supports Quicken and Quickbooks. To download Quicken files, clients will need to enroll in our
        Treasury Express tool on our business platform. You will be able to access your personal account via this
        Treasury tool. To request the ability to integrate your personal WaFd financial accounts, please fill out this{" "}
        <a
          id="quicken-access-form"
          type="button"
          href="/#"
          onClick={(e) => {
            e.preventDefault();
            showMktoForm(1266);
          }}
        >
          Quicken access form
        </a>
        .
        <br />
        Upon receipt of your request, it will take 2-3 business days for us to setup your access. A WaFd Banker will
        send you an email with instructions on how to access Treasury and how to download Quicken files.
      </>
    ),
    isReactElement: true
  };
  */

  return (
    <SecondaryLanding>
      <SEO {...seoData} />
      <Breadcrumb data={breadcrumbData} />
      <MktoForm />
      <section className="container pb-0">
        <div className="mb-5">
          <VimeoVideoFrame {...mobileBankingVideoData} />
        </div>
      </section>
      <FaqAccordion {...faqAccordionData} />
    </SecondaryLanding>
  );
};

export default OnlineBankingFAQ;
