import React from "react";
import { Link } from "gatsby";

import { useLanguageContext } from "../../contexts/language-context";

// Styles
import "./breadcrumb.bootstrap.scss";

const Breadcrumb = (props) => {
  const isSpanish = useLanguageContext();
  const data = props.data;
  const lng = data.length;

  if (lng > 2) {
    for (let a = 0; a < lng - 2; a++) {
      data[a].class = "d-none d-md-inline-flex";
    }
  }

  return (
    <div className={`container ${props.containerClass}`}>
      <nav aria-label="breadcrumb" className="breadcrumb-nav">
        <ol className="breadcrumb breadcrumb-list">
          <li className={`breadcrumb-item ${lng >= 2 ? "d-none d-md-inline-block" : ""}`}>
            <Link to={isSpanish ? "/es" : "/"} className="text-secondary text-decoration-none">
              WaFd Bank
            </Link>
          </li>
          {data.map((crumb) => {
            let activeClass = crumb.active ? "active" : "";
            return (
              <li key={crumb.id} className={`breadcrumb-item breadcrumb-list-item ${activeClass} ${crumb.class}`}>
                {crumb.active ? (
                  <span dangerouslySetInnerHTML={{ __html: crumb.title }} />
                ) : (
                  <Link className={`text-secondary breadcrumb-link text-decoration-none ${crumb.class}`} to={crumb.url}>
                    <span dangerouslySetInnerHTML={{ __html: crumb.title }} />
                  </Link>
                )}
              </li>
            );
          })}
        </ol>
      </nav>
    </div>
  );
};
export default Breadcrumb;

// NOTE: the following defaultProps will result in a breadcrumb: "WaFd Bank / Path Title / Active Page Title"
// where 'Active Page Title' is the active page. All non-active pages will be a Link to those urls.
Breadcrumb.defaultProps = {
  containerClass: "",
  data: [
    {
      id: 1,
      url: "/relative-path", // all urls should be relative to the root
      title: "Path Title"
    },
    {
      id: 2,
      title: "Active Page Title",
      active: true // the active page (no url needed). Previously was the class name "active", but now a boolean (either will work).
    }
  ]
};
